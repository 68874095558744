import styled from 'styled-components';

export const AgentLogo = styled.img`
  max-width: 31%;
  max-height: 42px;
  width: auto;
  height: auto;
  margin-right: 10px;
`;

export const PremierContainer = styled.div<{ color?: string }>`
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  height: 70px;
  background-color: ${(props) => props.color || props.theme.primary};
  border-radius: 0 0 4px 4px;
  gap: 15px;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  align-items: center;
  gap: 10px;
`;
